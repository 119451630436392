<!DOCTYPE html>
<html lang="en"
  class="notranslate"
  translate="no">

<head>
  <meta charset="utf-8">
  <meta name="viewport"
    content="width=device-width, 
               initial-scale=1">
</head>

<body>
  <div class="allgemein">
    <div class="headline">
      <h1>Admin-Bereich-Produkte-Bearbeiten</h1>
    </div>
    <mat-form-field class="search"
      appearance="standard">
      <mat-label>Produktfilter</mat-label>
      <input matInput
        (keyup)="applyFilter($event)"
        placeholder="Produktname"
        #input
        autocomplete="off">
    </mat-form-field>
    <table *ngFor="let elem of this.alleproduktetemp">
      <tr>
        <td id="picture3">
          <img mat-card-image
            src={{elem.bild}}
            alt="Bild kann nicht geladen werden"
            id="picture3">
        </td>
        <td class="produktname">
          {{elem.produktname}}
        </td>
        <!-- <td>
          {{elem.produktinformation}}
        </td>
        <td>
          {{elem.produktdetails}}
        </td> -->
        <td class="preis">
          {{elem.bestandsmenge}}&nbsp;Stück
        </td>
        <td class="preis">
          {{elem.preis | currency : 'EUR' : '' : '0.2' }}&nbsp;€
        </td>
        <!-- <td class="preis">
            {{elem.id}}
          </td> -->
        <!-- <td class="inputfield">
          <mat-form-field class="example-full-width"
            appearance="fill">
            <mat-label>Neue Menge</mat-label>
            <input matInput
              [(ngModel)]="elem.bestandsmenge">
          </mat-form-field>
        </td> -->
        <!-- <td class="inputfield">
          <mat-form-field class="example-full-width"
            appearance="fill">
            <mat-label>Neuer Preis</mat-label>
            <input matInput
              [(ngModel)]="elem.preis">
          </mat-form-field>
        </td>
        <td class="inputfield">
          <mat-form-field class="example-full-width"
            appearance="fill">
            <mat-label>Neuer Text</mat-label>
            <input matInput
              [(ngModel)]="elem.produktinformation">
          </mat-form-field>
        </td> -->
        <td>
          <button mat-button
            style="color:black;"
            (click)="artikelkategorie(elem);">Produkt bearbeiten</button>
        </td>
        <!-- <td class="preis">
          <button mat-button
            style="color:black;"
            (click)="produkteaenderung(elem, artikel);">Änderungen speichern</button>
        </td> -->
      </tr>
    </table>
    <div>
      <p *ngIf="this.alleproduktetemp.length === 0">
        Zu Ihrer Suchanfrage: "{{input.value}}" wurde kein passendes Ergebnis gefunden<br>
        Bitte versuchen Sie ein anderen Begriff
      </p>
    </div>
  </div>
  <div class="flex-container">
    <div class="flex-item-unten-left">
      <button mat-button
        style="color:black;"
        (click)="navigateshop();">Zum Shop</button>
    </div>
    <div class="flex-item-unten-mitte">
      <button mat-button
        style="color:black;"
        (click)="navigateadminbereich()">Admin-Bereich</button>
    </div>
    <div class="flex-item-unten-right">
      <button mat-button
        style="color:black;"
        (click)="logout()">Abmelden</button>
    </div>
  </div>
</body>

</html>