<!DOCTYPE html>
<html lang="en" class="notranslate" translate="no">    
<head>
<meta charset="utf-8">
<meta name="viewport" 
      content="width=device-width, 
               initial-scale=1">
</head>
<body>
  <div class="allgemein">
    <div class="headline">
      <h1>Admin-Bereich-Produkte entfernen</h1>
    </div>
  <mat-form-field appearance="standard">
    <mat-label>Produktfilter</mat-label>
    <input matInput (keyup)="applyFilter($event)" placeholder="Produktname" #input autocomplete="off" >
  </mat-form-field>  
  <table *ngFor = "let elem of this.alleproduktetemp">
      <tr>
          <td id="picture3">
              <img mat-card-image src= {{elem.bild}} alt="Bild kann nicht geladen werden" id="picture3">  
          </td> 
          <td class="produktname">
              {{elem.produktname}}
          </td>
          <td class="preis">
              {{elem.preis | currency : 'EUR' : '' : '0.2' }}&nbsp;€
          </td>
          <td class="preis">
            <button mat-button (click) = "this.artikelkategorie(elem)" style ="color:black;">Produkt löschen</button>
        </td>
      </tr>
  </table>
  <div>
    <p *ngIf="this.alleproduktetemp.length === 0 && this.input.value != ''">
      Zu Ihrer Suchanfrage: "{{input.value}}" wurde kein passendes Ergebnis gefunden<br>
      Bitte versuchen Sie ein anderen Begriff
    </p>
    <p *ngIf="this.input.value === ''">
      Bitte geben sie einen Suchbegriff ein.
    </p>
  </div>
  </div>
    <div class="flex-container">
        <div class="flex-item-unten-left">
          <button mat-button style ="color:black;" (click) = "navigateshop();">Zum Shop</button>
        </div>
        <div class="flex-item-unten-mitte">
            <button mat-button style ="color:black;" (click) = "navigateadminbereich()">Admin-Bereich</button>
        </div>
        <div class="flex-item-unten-right">
          <button mat-button style ="color:black;" (click) = "logout()">Abmelden</button>
        </div>
      </div>
</body>
</html>