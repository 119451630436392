<!DOCTYPE html>
<html lang="en" class="notranslate" translate="no">    
<head>
<meta charset="utf-8">
<meta name="viewport" 
      content="width=device-width, 
               initial-scale=1">
</head>
<body>
  <div class="allgemein">
    <div class="headline">
      <h1>Admin-Bereich-Bewertungen</h1>
    </div>
        <div class="flex-item-left" *ngFor = "let elem of this.DbService.response">
            <mat-card-content id="unten">
                <p>
                Folgende Bewertung wurde von {{elem.vorname}}&nbsp;{{elem.nachname}}&nbsp;({{elem.synonym}})&nbsp;erstellt:
                </p>
                <p>
                {{elem.bewertung}}
                </p>
                <p>
                <button mat-button style ="color:white;" (click) = "uebergabebewertung(elem);">Veröffentlichen</button>
                <button mat-button style ="color:white;" (click) = "loeschebewertung(elem.id);">Löschen</button>
                </p>
            </mat-card-content>
        </div>
    </div>
    <div class="flex-container">
        <div class="flex-item-unten-left">
            <button mat-button style ="color:black;" (click) = "navigateshop();">Zum Shop</button>
        </div>
        <div class="flex-item-unten-mitte">
            <button mat-button style ="color:black;" (click) = "navigateadminbereich()">Zum Admin-Bereich</button>
        </div>
        <div class="flex-item-unten-right">
            <button mat-button style ="color:black;" (click) = "logout()">Abmelden</button>
        </div>
      </div>
</body>
</html>