        <footer>
          <div class="container">
              <section class="ft-main">
                <div class="ft-main-item">
                  <button mat-button style ="color:white;" (click) ="navigateImpressum();">Impressum</button>
                </div>
                <div class="ft-main-item">
                  <button mat-button style ="color:white;" (click) ="navigateWiderruf();">Widerrufsbelehrung</button>
                </div>
                <div class="ft-main-item">
                  <button mat-button style ="color:white;" (click) ="navigateDatenschutz();">Datenschutz</button>
                </div>
                <div class="ft-main-item">
                  <button mat-button style ="color:white;" (click) ="navigateAGB();">AGB</button>
                </div>
                <div class="ft-main-item">
                    <button mat-button style ="color:white;" (click) ="navigateVersand();">Zahlung/Versand</button>
                  </div>
                <div class="ft-main-item">
                  <button mat-button style ="color:white;" (click) ="navigateBewertungen();">Bewertungen</button>
                </div>
              </section>
            </div>
            </footer>