import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Artikel } from '../Services/artikel.model';
import { DatenbankService } from '../Services/datenbank.service';

@Component({
  selector: 'app-admin-produkte-loeschen',
  templateUrl: './admin-produkte-loeschen.component.html',
  styleUrls: ['./admin-produkte-loeschen.component.css']
})
export class AdminProdukteLoeschenComponent implements OnInit {


  alleprodukte: Artikel[]=[];
  alleproduktetemp:Artikel[]=[];
  produkte: Artikel[];
  neuseAttribut: string;
  attribut: string;
  gesamt: Artikel[];
  user:string;

  http: any;

  constructor(private DbService: DatenbankService, private  router: Router) { }

  ngOnInit(): void {
    this.user = localStorage.getItem('admin');
    this.datenpruefung();
    this.gesamtprodukte();
  }

  applyFilter(event: Event) {
      const filterValue = (event.target as HTMLInputElement).value;
      this.alleproduktetemp = this.alleprodukte.filter(x => (x.produktname.toLowerCase().includes(filterValue.toLowerCase())))
  }

  async gesamtprodukte(){
    this.alleprodukte=[];        
     
    
    await this.DbService.getBetonlampenProdukte().toPromise().then(data =>{
          this.DbService.response = data
          this.neuseAttribut = this.DbService.response, JSON.stringify(this.neuseAttribut)
          this.attribut = this.DbService.response
          this.neuseAttribut = JSON.stringify(this.neuseAttribut);
   
          for(let i=0; this.DbService.response.length > i; i++){
            this.alleprodukte.push(this.DbService.response[i])
          }
    })

    await this.DbService.getBetonProdukte().toPromise().then(data =>{
          this.DbService.response = data
          this.neuseAttribut = this.DbService.response, JSON.stringify(this.neuseAttribut)
          this.attribut = this.DbService.response
          this.neuseAttribut = JSON.stringify(this.neuseAttribut);
          for(let i=0; this.DbService.response.length > i; i++){
            this.alleprodukte.push(this.DbService.response[i])
          }
    })

    await this.DbService.getHolzaufstellerProdukte().toPromise().then(data =>{
          this.DbService.response = data
          this.neuseAttribut = this.DbService.response, JSON.stringify(this.neuseAttribut)
          this.attribut = this.DbService.response
          this.neuseAttribut = JSON.stringify(this.neuseAttribut);
          for(let i=0; this.DbService.response.length > i; i++){
            this.alleprodukte.push(this.DbService.response[i])
          }
    })

    await this.DbService.getProdukte().toPromise().then(data =>{
          this.DbService.response = data
          this.neuseAttribut = this.DbService.response, JSON.stringify(this.neuseAttribut)
          this.attribut = this.DbService.response
          this.neuseAttribut = JSON.stringify(this.neuseAttribut);
          for(let i=0; this.DbService.response.length > i; i++){
            this.alleprodukte.push(this.DbService.response[i])
          }
    })

    await this.DbService.getKantholzProdukte().toPromise().then(data =>{
          this.DbService.response = data
          this.neuseAttribut = this.DbService.response, JSON.stringify(this.neuseAttribut)
          this.attribut = this.DbService.response
          this.neuseAttribut = JSON.stringify(this.neuseAttribut);
          for(let i=0; this.DbService.response.length > i; i++){
            this.alleprodukte.push(this.DbService.response[i])
          }
    })

    await this.DbService.getHolzschilderProdukte().toPromise().then(data =>{
          this.DbService.response = data
          this.neuseAttribut = this.DbService.response, JSON.stringify(this.neuseAttribut)
          this.attribut = this.DbService.response
          this.neuseAttribut = JSON.stringify(this.neuseAttribut);
          for(let i=0; this.DbService.response.length > i; i++){
            this.alleprodukte.push(this.DbService.response[i])
          }
    })

    await this.DbService.getHolzfigurenProdukte().toPromise().then(data =>{
          this.DbService.response = data
          this.neuseAttribut = this.DbService.response, JSON.stringify(this.neuseAttribut)
          this.attribut = this.DbService.response
          this.neuseAttribut = JSON.stringify(this.neuseAttribut);
          for(let i=0; this.DbService.response.length > i; i++){
            this.alleprodukte.push(this.DbService.response[i])
          }
    })

//     await this.DbService.getKeraminfigurenProdukte().toPromise().then(data =>{
//       this.DbService.response = data
//       this.neuseAttribut = this.DbService.response, JSON.stringify(this.neuseAttribut)
//       this.attribut = this.DbService.response
//       this.neuseAttribut = JSON.stringify(this.neuseAttribut);
//       for(let i=0; this.DbService.response.length > i; i++){
//         this.alleprodukte.push(this.DbService.response[i])
//       }
// })

    await this.DbService.getRaysinfigurenProdukte().toPromise().then(data =>{
      this.DbService.response = data
      this.neuseAttribut = this.DbService.response, JSON.stringify(this.neuseAttribut)
      this.attribut = this.DbService.response
      this.neuseAttribut = JSON.stringify(this.neuseAttribut);
      for(let i=0; this.DbService.response.length > i; i++){
        this.alleprodukte.push(this.DbService.response[i])
      }
    })

    await this.DbService.getKloetzchenProdukte().toPromise().then(data =>{
      this.DbService.response = data
      this.neuseAttribut = this.DbService.response, JSON.stringify(this.neuseAttribut)
      this.attribut = this.DbService.response
      this.neuseAttribut = JSON.stringify(this.neuseAttribut);
      for(let i=0; this.DbService.response.length > i; i++){
        this.alleprodukte.push(this.DbService.response[i])
      }
    })
    this.alleproduktetemp = this.alleprodukte;

  }

  datenpruefung(){
    if(localStorage.getItem('admin') === null)
    this.router.navigate(['Adminlogin']);
  }

  logout(){
    localStorage.removeItem('admin')
    this.router.navigate(['Adminlogin']);
    }

    navigateshop(){
      window.location.href = "https://chrisubis-hobby.de/Shop";
    }

    navigateadminbereich(){
      this.router.navigate(['Admin'])
    }

    async artikelkategorie(artikel: Artikel) {
      let found =false;
      switch (artikel.kategorie) {
        
        case 'Betonleuchten': {
          await this.DbService.deletebetonlampenprodukte(artikel.id).toPromise();
          found=true;
          break;
        }
        case 'Betonfiguren': {
          await this.DbService.deletebetonfigurenprodukte(artikel.id).toPromise();
          found=true;
          break;
        }
        case 'Schlummerlampen': {
          await this.DbService.deleteschlummerlampenprodukte(artikel.id).toPromise();
          found=true;
          break;
        }
        case 'Holzaufsteller': {
          await this.DbService.deleteholzaufstellerprodukte(artikel.id).toPromise();
          found=true;
          break;
        }
        case 'Kantholz': {
          await this.DbService.deletekantholzprodukte(artikel.id).toPromise();
          found=true;
          break;
        }
        case 'Holzfiguren': {
          await this.DbService.deleteholzfigurenprodukte(artikel.id).toPromise();
          found=true;
          break;
        }
        case 'Birkenstammdeko': {
          await this.DbService.deletebirkenstammdekoprodukte(artikel.id).toPromise();
          found=true;
          break;
        }
        case 'Holzschilder': {
          await this.DbService.deleteholzschilderprodukte(artikel.id).toPromise();
          found=true;
          break;
        }
        // case 'Keraminfiguren': {
        //   await this.DbService.deletekeraminfigurenprodukte(artikel.id).toPromise();
        //   found=true;
        //   break;
        // }
        case 'Raysinfiguren': {
          await this.DbService.deleteraysinfigurenprodukte(artikel.id).toPromise();
          found=true;
          break;
        }
        case 'Kloetzchen': {
          await this.DbService.deletekloetzchenprodukte(artikel.id).toPromise();
          found=true;
          break;
        }
        default: {
          console.log('Es wurde keine passende Auswahl an Produkten gefunden');
        }
      };
        if(found){
          let index = this.alleproduktetemp.indexOf(artikel);
          this.alleproduktetemp.splice(index,1);
          found = false;
                }
    }


}
