import { Component, OnInit, Output, EventEmitter} from '@angular/core';
import {Router} from '@angular/router';
import { NavigateDataService } from '../Services/navigate.data.service';
@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css'],
})
export class HeaderComponent implements OnInit {

  info:number;
  wunschlistenmenge:number;

  public url: string = "https://api-chrisubis-hobby.de/Api";

  @Output() public sidenavToggle = new EventEmitter();

  constructor(private router: Router, public navigateData: NavigateDataService) { }

  ngOnInit(): void {
    window.scrollTo(1, 1)
  }
  navigateHome(){
    window.location.href = "https://chrisubis-hobby.de/Home";
  }
  navigateKontakt(){
    window.location.href = "https://chrisubis-hobby.de/Kontakt";
  }
  navigateShop(){
    window.location.href = "https://chrisubis-hobby.de/Shop";
  }
  navigateWarenkorb(){
    window.location.href = "https://chrisubis-hobby.de/Warenkorb";
  }
  navigatewunschliste(){
    window.location.href = "https://chrisubis-hobby.de/Wunschliste";
  }
  navigateproduktsuche(){
    window.location.href = "https://chrisubis-hobby.de/Produktsuche";
  }

  public onToggleSidenav = () => {
    this.sidenavToggle.emit();
  }
}
