<!DOCTYPE html>
<html lang="en" class="notranslate" translate="no">    
<head>
<meta charset="utf-8">
<meta name="viewport" 
      content="width=device-width, 
               initial-scale=1">
</head>
<body>
    <div class="allgemein">
        <div class="headline">
            <h1>Admin-Login-Bereich</h1>
        </div>
      <mat-form-field class="example-full-width" appearance="fill">
        <mat-label>User</mat-label>
        <input matInput [(ngModel)]="this.user" id="user" autocomplete="off">
        
      </mat-form-field>

      <mat-form-field appearance="fill">
        <mat-label>Passwort</mat-label>
        <input matInput [type]="hide ? 'password' : 'text'" [(ngModel)]="this.password" id="password">
        <button mat-icon-button matSuffix (click)="hide = !hide" [attr.aria-label]="'Hide password'" [attr.aria-pressed]="hide">
          <mat-icon>{{hide ? 'visibility_off' : 'visibility'}}</mat-icon>
        </button>
      </mat-form-field>
    </div>
    <div class="flex-container">
        <div class="flex-item-unten-left">
          <button mat-button style ="color:black;" (click) = "navigateshop();">Zum Shop</button>
        </div>
        <div class="flex-item-unten-right">
          <!-- (window:keypress)="datenpruefung(this.user, this.password)" -->
          <button mat-button style ="color:black;" (click) = "datenpruefung(this.user, this.password)">Anmelden</button>
        </div>
      </div>
</body>
</html>