import { RouterModule, Routes } from '@angular/router';
import { NgModule } from '@angular/core';
import { AdminBereichComponent } from './admin-bereich/admin-bereich.component';
import { AdminLoginComponent } from './admin-login/admin-login.component';
import { AdminBereichProdukteComponent } from './admin-bereich-produkte/admin-bereich-produkte.component';
import { AdminBereichBewertungenComponent } from './admin-bereich-bewertungen/admin-bereich-bewertungen.component';
import { AdminProdukteErstellenComponent } from './admin-produkte-erstellen/admin-produkte-erstellen.component';
import { AdminProdukteLoeschenComponent } from './admin-produkte-loeschen/admin-produkte-loeschen.component';
import { AdminProdukteAenderungComponent } from './admin-produkte-aenderung/admin-produkte-aenderung.component';
import { AdminProdukteDetailsAenderungComponent } from './admin-produkte-details-aenderung/admin-produkte-details-aenderung.component';

const routes: Routes = [
  { path: 'Admin', component: AdminBereichComponent },
  { path: 'Adminlogin', component: AdminLoginComponent },
  { path: 'Adminprodukte', component: AdminBereichProdukteComponent },
  { path: 'Adminbewertungen', component: AdminBereichBewertungenComponent },
  { path: 'Adminprodukteentfernen', component: AdminProdukteLoeschenComponent },
  {
    path: 'Adminprodukteerstellen',
    component: AdminProdukteErstellenComponent,
  },
  { path: 'Adminprodukteaendern', component: AdminProdukteAenderungComponent },
  {
    path: 'Adminproduktedetailsaendern/:bezeichnung/:id',
    component: AdminProdukteDetailsAenderungComponent,
  },

  { path: '**', redirectTo: 'Admin', pathMatch: 'full' },
];
@NgModule({
  imports: [RouterModule.forRoot(routes, { useHash: true })],
  exports: [RouterModule],
})
export class AppRoutingModule {}
