<!DOCTYPE html>
<html lang="de"
    class="notranslate"
    translate="no">

<head>
    <meta charset="utf-8">
    <meta name="viewport"
        content="width=device-width, 
               initial-scale=1">
</head>

<body>
    <div class="allgemein">
        <div class="headline">
            <h1>
                {{this.artikel.produktname}}
            </h1>
        </div>
        <div class="flex-container">
            <div class="flex-item-left">
                <img mat-card-image
                    src={{this.artikel.bild}}
                    alt="Bild kann nicht geladen werden"
                    id="picture3">
            </div>

            <div class="flex-item-right">
                <p>
                    <strong>Artikelinformationen & Maße:</strong>
                </p>
                <p>
                    <mat-form-field class="example-full-width"
                        appearance="fill">
                        <mat-label>Produktname</mat-label>
                        <input matInput
                            [(ngModel)]="this.artikel.produktname">
                    </mat-form-field>
                </p>
                <p>
                    <mat-form-field class="example-full-width"
                        appearance="fill">
                        <mat-label>H x B x T</mat-label>
                        <input matInput
                            [(ngModel)]="this.artikel.h_x_b_x_l">
                    </mat-form-field>
                </p>
                <!-- <p>
                    <mat-form-field class="example-full-width"
                        appearance="fill">
                        <mat-label>Produktinformation</mat-label>
                        <input matInput
                            [(ngModel)]="this.artikel.produktinformation">
                    </mat-form-field>
                </p> -->
                <p>
                    <mat-form-field class="example-full-width"
                        appearance="fill">
                        <mat-label>Produktbeschreibung</mat-label>
                        <input matInput
                            [(ngModel)]="this.artikel.produktbeschreibung">
                    </mat-form-field>
                </p>
                <p>
                    <mat-form-field class="example-full-width"
                        appearance="fill">
                        <mat-label>Besonderheiten</mat-label>
                        <input matInput
                            [(ngModel)]="this.artikel.besonderheiten">
                    </mat-form-field>
                </p>
                <p>
                    <mat-form-field class="example-full-width"
                        appearance="fill">
                        <mat-label>Preis</mat-label>
                        <input matInput
                            [(ngModel)]="this.artikel.preis">
                    </mat-form-field>
                </p>
                <p>
                    <mat-form-field class="example-full-width"
                        appearance="fill">
                        <mat-label>Menge</mat-label>
                        <input matInput
                            [(ngModel)]="this.artikel.bestandsmenge">
                    </mat-form-field>
                </p>
                <div>
                    <p>
                        <button mat-button
                            style="color:black;"
                            align="start"
                            id="buttonone"
                            (click)="produkteaenderung(artikel);">Änderungen speichern</button>
                        <button mat-button
                            style="color:black;"
                            align="end"
                            id="buttontwo"
                            (click)="navigateHome(artikel);">Zurück</button>
                    </p>
                </div>
            </div>
        </div>
    </div>
</body>

</html>