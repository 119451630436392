import { Component, OnInit } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { Artikel } from '../Services/artikel.model';
import { DatenbankService } from '../Services/datenbank.service';
import { NavigateDataService } from '../Services/navigate.data.service';

@Component({
  selector: 'app-admin-produkte-aenderung',
  templateUrl: './admin-produkte-aenderung.component.html',
  styleUrls: ['./admin-produkte-aenderung.component.css'],
})
export class AdminProdukteAenderungComponent implements OnInit {
  alleprodukte: Artikel[] = [];
  alleproduktetemp: Artikel[] = [];
  produkte: Artikel[];
  neuseAttribut: string;
  attribut: string;
  gesamt: Artikel[];
  user: string;
  produktkategorie: string;
  ausschnitt: any;
  ausschnittid: any;
  produktid: any;

  http: any;

  artikelKategorie: string;

  constructor(
    private DbService: DatenbankService,
    private router: Router,
    private snackBar: MatSnackBar,
    private navigateData: NavigateDataService
  ) {}

  ngOnInit(): void {
    this.user = localStorage.getItem('admin');
    this.datenpruefung();
    this.gesamtprodukte();
    this.artikelKategorie = this.navigateData.getArtikelKategorie();
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.alleproduktetemp = this.alleprodukte.filter((x) =>
      x.produktname.toLowerCase().includes(filterValue.toLowerCase())
    );
  }

  navigateProdukte(ausschnitt) {
    this.router.navigate([
      'Adminproduktedetailsaendern/' +
        this.ausschnitt +
        '/' +
        this.ausschnittid,
    ]);
  }

  async artikelkategorie(artikel: Artikel) {
    let found = false;
    switch (artikel.kategorie) {
      case 'Betonleuchten': {
        await this.DbService.getBetonlampenProduktbyID(artikel.id).toPromise();
        this.produktkategorie = JSON.stringify(artikel);
        this.ausschnitt = this.produktkategorie.match(artikel.kategorie);
        this.produktid = artikel.id;
        this.ausschnittid = this.produktkategorie.match(this.produktid);
        this.navigateProdukte(this.ausschnittid);
        break;
      }
      case 'Betonfiguren': {
        await this.DbService.getBetonfigurenProduktbyID(artikel.id).toPromise();
        this.produktkategorie = JSON.stringify(artikel);
        this.ausschnitt = this.produktkategorie.match(artikel.kategorie);
        this.produktid = artikel.id;
        this.ausschnittid = this.produktkategorie.match(this.produktid);
        this.navigateProdukte(this.ausschnittid);
        break;
      }
      case 'Schlummerlampen': {
        await this.DbService.getProduktbyID(artikel.id).toPromise();
        this.produktkategorie = JSON.stringify(artikel);
        this.ausschnitt = this.produktkategorie.match(artikel.kategorie);
        this.produktid = artikel.id;
        this.ausschnittid = this.produktkategorie.match(this.produktid);
        this.navigateProdukte(this.ausschnittid);
        break;
      }
      case 'Holzaufsteller': {
        await this.DbService.getHolzaufstellerProduktbyID(
          artikel.id
        ).toPromise();
        this.produktkategorie = JSON.stringify(artikel);
        this.ausschnitt = this.produktkategorie.match(artikel.kategorie);
        this.produktid = artikel.id;
        this.ausschnittid = this.produktkategorie.match(this.produktid);
        this.navigateProdukte(this.ausschnittid);
        break;
      }
      case 'Kantholz': {
        await this.DbService.getKantholzProduktbyID(artikel.id).toPromise();
        this.produktkategorie = JSON.stringify(artikel);
        this.ausschnitt = this.produktkategorie.match(artikel.kategorie);
        this.produktid = artikel.id;
        this.ausschnittid = this.produktkategorie.match(this.produktid);
        this.navigateProdukte(this.ausschnittid);
        break;
      }
      case 'Holzfiguren': {
        await this.DbService.getHolzfigurenProduktbyID(artikel.id).toPromise();
        this.produktkategorie = JSON.stringify(artikel);
        this.ausschnitt = this.produktkategorie.match(artikel.kategorie);
        this.produktid = artikel.id;
        this.ausschnittid = this.produktkategorie.match(this.produktid);
        this.navigateProdukte(this.ausschnittid);
        break;
      }
      case 'Birkenstammdeko': {
        await this.DbService.getBirkenstammdekoProduktbyID(
          artikel.id
        ).toPromise();
        this.produktkategorie = JSON.stringify(artikel);
        this.ausschnitt = this.produktkategorie.match(artikel.kategorie);
        this.produktid = artikel.id;
        this.ausschnittid = this.produktkategorie.match(this.produktid);
        this.navigateProdukte(this.ausschnittid);
        break;
      }
      case 'Holzschilder': {
        await this.DbService.getHolzschilderProduktbyID(artikel.id).toPromise();
        this.produktkategorie = JSON.stringify(artikel);
        this.ausschnitt = this.produktkategorie.match(artikel.kategorie);
        this.produktid = artikel.id;
        this.ausschnittid = this.produktkategorie.match(this.produktid);
        this.navigateProdukte(this.ausschnittid);
        break;
      }
      // case 'Keraminfiguren': {
      //   await this.DbService.getKeraminfigurenProduktbyID(
      //     artikel.id
      //   ).toPromise();
      //   this.produktkategorie = JSON.stringify(artikel);
      //   this.ausschnitt = this.produktkategorie.match(artikel.kategorie);
      //   this.produktid = artikel.id;
      //   this.ausschnittid = this.produktkategorie.match(this.produktid);
      //   this.navigateProdukte(this.ausschnittid);
      //   break;
      // }
      case 'Raysinfiguren': {
        await this.DbService.getRaysinfigurenProduktbyID(
          artikel.id
        ).toPromise();
        this.produktkategorie = JSON.stringify(artikel);
        this.ausschnitt = this.produktkategorie.match(artikel.kategorie);
        this.produktid = artikel.id;
        this.ausschnittid = this.produktkategorie.match(this.produktid);
        this.navigateProdukte(this.ausschnittid);
        break;
      }

      case 'Kloetzchen': {
        await this.DbService.getKloetzchenProduktbyID(
          artikel.id
        ).toPromise();
        this.produktkategorie = JSON.stringify(artikel);
        this.ausschnitt = this.produktkategorie.match(artikel.kategorie);
        this.produktid = artikel.id;
        this.ausschnittid = this.produktkategorie.match(this.produktid);
        this.navigateProdukte(this.ausschnittid);
        break;
      }
      default: {
        console.log('Es wurde keine passende Auswahl an Produkten gefunden');
      }
    }
  }

  async gesamtprodukte() {
    this.alleprodukte = [];

    await this.DbService.getBetonlampenProdukte()
      .toPromise()
      .then((data) => {
        this.DbService.response = data;
        (this.neuseAttribut = this.DbService.response),
          JSON.stringify(this.neuseAttribut);
        this.attribut = this.DbService.response;
        this.neuseAttribut = JSON.stringify(this.neuseAttribut);

        for (let i = 0; this.DbService.response.length > i; i++) {
          this.alleprodukte.push(this.DbService.response[i]);
        }
      });

    await this.DbService.getBetonProdukte()
      .toPromise()
      .then((data) => {
        this.DbService.response = data;
        (this.neuseAttribut = this.DbService.response),
          JSON.stringify(this.neuseAttribut);
        this.attribut = this.DbService.response;
        this.neuseAttribut = JSON.stringify(this.neuseAttribut);
        for (let i = 0; this.DbService.response.length > i; i++) {
          this.alleprodukte.push(this.DbService.response[i]);
        }
      });

    await this.DbService.getHolzaufstellerProdukte()
      .toPromise()
      .then((data) => {
        this.DbService.response = data;
        (this.neuseAttribut = this.DbService.response),
          JSON.stringify(this.neuseAttribut);
        this.attribut = this.DbService.response;
        this.neuseAttribut = JSON.stringify(this.neuseAttribut);
        for (let i = 0; this.DbService.response.length > i; i++) {
          this.alleprodukte.push(this.DbService.response[i]);
        }
      });

    await this.DbService.getProdukte()
      .toPromise()
      .then((data) => {
        this.DbService.response = data;
        (this.neuseAttribut = this.DbService.response),
          JSON.stringify(this.neuseAttribut);
        this.attribut = this.DbService.response;
        this.neuseAttribut = JSON.stringify(this.neuseAttribut);
        for (let i = 0; this.DbService.response.length > i; i++) {
          this.alleprodukte.push(this.DbService.response[i]);
        }
      });

    await this.DbService.getKantholzProdukte()
      .toPromise()
      .then((data) => {
        this.DbService.response = data;
        (this.neuseAttribut = this.DbService.response),
          JSON.stringify(this.neuseAttribut);
        this.attribut = this.DbService.response;
        this.neuseAttribut = JSON.stringify(this.neuseAttribut);
        for (let i = 0; this.DbService.response.length > i; i++) {
          this.alleprodukte.push(this.DbService.response[i]);
        }
      });

    await this.DbService.getHolzschilderProdukte()
      .toPromise()
      .then((data) => {
        this.DbService.response = data;
        (this.neuseAttribut = this.DbService.response),
          JSON.stringify(this.neuseAttribut);
        this.attribut = this.DbService.response;
        this.neuseAttribut = JSON.stringify(this.neuseAttribut);
        for (let i = 0; this.DbService.response.length > i; i++) {
          this.alleprodukte.push(this.DbService.response[i]);
        }
      });

    await this.DbService.getHolzfigurenProdukte()
      .toPromise()
      .then((data) => {
        this.DbService.response = data;
        (this.neuseAttribut = this.DbService.response),
          JSON.stringify(this.neuseAttribut);
        this.attribut = this.DbService.response;
        this.neuseAttribut = JSON.stringify(this.neuseAttribut);
        for (let i = 0; this.DbService.response.length > i; i++) {
          this.alleprodukte.push(this.DbService.response[i]);
        }
      });

    // await this.DbService.getKeraminfigurenProdukte()
    //   .toPromise()
    //   .then((data) => {
    //     this.DbService.response = data;
    //     (this.neuseAttribut = this.DbService.response),
    //       JSON.stringify(this.neuseAttribut);
    //     this.attribut = this.DbService.response;
    //     this.neuseAttribut = JSON.stringify(this.neuseAttribut);
    //     for (let i = 0; this.DbService.response.length > i; i++) {
    //       this.alleprodukte.push(this.DbService.response[i]);
    //     }
    //   });

    await this.DbService.getRaysinfigurenProdukte()
      .toPromise()
      .then((data) => {
        this.DbService.response = data;
        (this.neuseAttribut = this.DbService.response),
          JSON.stringify(this.neuseAttribut);
        this.attribut = this.DbService.response;
        this.neuseAttribut = JSON.stringify(this.neuseAttribut);
        for (let i = 0; this.DbService.response.length > i; i++) {
          this.alleprodukte.push(this.DbService.response[i]);
        }
      });

      await this.DbService.getKloetzchenProdukte()
      .toPromise()
      .then((data) => {
        this.DbService.response = data;
        (this.neuseAttribut = this.DbService.response),
          JSON.stringify(this.neuseAttribut);
        this.attribut = this.DbService.response;
        this.neuseAttribut = JSON.stringify(this.neuseAttribut);
        for (let i = 0; this.DbService.response.length > i; i++) {
          this.alleprodukte.push(this.DbService.response[i]);
        }
      });
    this.alleproduktetemp = this.alleprodukte;
  }

  datenpruefung() {
    if (localStorage.getItem('admin') === null)
      this.router.navigate(['Adminlogin']);
  }

  logout() {
    localStorage.removeItem('admin');
    this.router.navigate(['Adminlogin']);
  }

  navigateshop() {
    window.location.href = 'https://chrisubis-hobby.de/Shop';
  }

  navigateadminbereich() {
    this.router.navigate(['Admin']);
  }

  async produkteaenderung(elem, artikel: Artikel) {
    switch (elem.kategorie) {
      case 'Betonleuchten': {
        await this.DbService.putproduktaenderungbetonlampenprodukte(
          elem
        ).toPromise();
        break;
      }
      case 'Betonfiguren': {
        await this.DbService.putproduktaenderungbetonfigurenprodukte(
          elem
        ).toPromise();
        break;
      }
      case 'Schlummerlampen': {
        await this.DbService.putproduktaenderungschlummerlampenprodukte(
          elem
        ).toPromise();
        break;
      }
      case 'Holzaufsteller': {
        await this.DbService.putproduktaenderungholzaufstellerprodukte(
          elem
        ).toPromise();
        break;
      }
      case 'Kantholz': {
        await this.DbService.putproduktaenderungkantholzprodukte(
          elem
        ).toPromise();
        break;
      }
      case 'Holzfiguren': {
        await this.DbService.putproduktaenderungholzfigurenprodukte(
          elem
        ).toPromise();
        break;
      }
      case 'Birkenstammdeko': {
        await this.DbService.putproduktaenderungbirkenstammdekoprodukte(
          elem
        ).toPromise();
        break;
      }
      case 'Holzschilder': {
        await this.DbService.putproduktaenderungholzschilderprodukte(
          elem
        ).toPromise();
        break;
      }
      case 'Raysinfiguren': {
        await this.DbService.putproduktaenderungraysinfigurenprodukte(
          elem
        ).toPromise();
        break;
      }
      case 'Kloetzchen': {
        await this.DbService.putproduktaenderungkloetzchenprodukte(
          elem
        ).toPromise();
        break;
      }
      // case 'Keraminfiguren': {
      //   await this.DbService.putproduktaenderungkeraminfigurenprodukte(
      //     elem
      //   ).toPromise();
      //   break;
      // }
      default: {
        console.log('Es wurde keine passende Auswahl an Produkten gefunden');
        break;
      }
    }
    this.snackBar.open('Die Änderungen wurden übernommen', '', {
      duration: 3000,
    });
  }
}
